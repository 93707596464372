import { min } from 'd3';

const d3 = require('d3');
globalThis.ticks = 20;

const Timeline = {
    init: () => {
        globalThis.timelineContainer = d3.select('.items')
            .append('div')
            // .attr("class", "inner-viz")
            .attr("id", "timeline-js")

        return Timeline.variables();
    },
    draw: (datum) => {
        d3.selectAll("#timeline-js  div").remove();

        globalThis.scaleWidth = d3.scaleLinear()
            .domain(Timeline.extent())
            .range([0, 100])

        let rows = timelineContainer.selectAll("div")

        const bph = datum.filter(d => d?.["item number"].includes("BPH"))
        const news = datum.filter(d => d?.["item number"].includes("Newsletter"))

        // console.log(news)
        // console.log(bph)

        rows.data([...news, ...bph])
            .enter()
            .append(function (d) {
                if (d.media) {
                    return document.createElement("a");
                } else {
                    return document.createElement("div");
                }
            })
            .attr("target", function (d) {
                return d.media ? "_blank" : null;
            })
            .attr("href", function (d) {
                return d.media ? d.media : null;
            })
            .attr('attr', (d) => d["Sender"])
            .attr("class", (d) => {
                // return "words"
                if (scaleWidth(Timeline.distanceDate(Timeline.cleanDate(d["Letter date"]), Timeline.cleanDate(d["Newsletter date"])))) {
                    return "words"
                }
                else {
                    return "words nodate"
                }
            })
            .on("mouseover focus", function (e, i) {
                const sender = d3.select(`.person[value="${i.Sender}"] ~ span`).style("background", "var(--selection)")
                if (sender.node()) {
                    sender.node().scrollIntoView();
                }
                const places = d3.select(`.place[value="${i['Sender location']}"] ~ span`).style("background", "var(--selection)")
                if (places.node()) {
                    places.node().scrollIntoView();
                }
                const tag = d3.select(`.tag[value="${i['Letter tags 1']}"] ~ span`).style("background", "var(--selection)")
                if (tag.node()) {
                    tag.node().scrollIntoView();
                }
                const cleanDate = i['Newsletter date'].split("-").slice(-1)[0]
                const dates = d3.select(`.date[value="${cleanDate}"] ~ span`).style("background", "var(--selection)")
                if (dates.node()) {
                    dates.node().scrollIntoView();
                }
            })
            .on("mouseout", function (e, i) {
                d3.selectAll("input ~ span").style("background", "unset")
                d3.selectAll("input:checked ~ span").style("background", "var(--color-3)")
            })
            .append("p")
            .style("background", (d) => {
                // return `100%`
                if (scaleWidth(Timeline.distanceDate(Timeline.cleanDate(d["Letter date"]), Timeline.cleanDate(d["Newsletter date"])))) {
                    return `linear-gradient(90deg, var(--color-3) 0%, var(--color-3) ${scaleWidth(Timeline.distanceDate(Timeline.cleanDate(d["Letter date"]), Timeline.cleanDate(d["Newsletter date"]))) + "%"}, rgba(255,255,255,0) ${scaleWidth(Timeline.distanceDate(Timeline.cleanDate(d["Letter date"]), Timeline.cleanDate(d["Newsletter date"]))) + 20 + "%"})`
                    return scaleWidth(Timeline.distanceDate(Timeline.cleanDate(d["Letter date"]), Timeline.cleanDate(d["Newsletter date"]))) + "%"
                }
                else {
                    return `linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 100%)`
                }
            })
            .text((d) => d["Letter topic"])
            .append("quote")
            .html((d) => {
                return `<strong>${d["item number"]}</strong>, ${d["Letter date"] || "undated"}, ${d["Sender"]}, ${d["Sender location"]}, <em>${d["Subject [factual]"]}</em>`
                // if (!scaleWidth(Timeline.distanceDate(Timeline.cleanDate(d["Letter date"]), Timeline.cleanDate(d["Newsletter date"])))) {
                //     let yearNewsletter = d["Newsletter date"].split("-").slice(-1)[0]
                //     return d["Newsletter date"] && d["Letter tags 1"] ?`${d["Letter tags 1"]}, ${yearNewsletter}` : ""
                // }
                // else {
                //     return `Replied after <strong>${Timeline.distanceDate(Timeline.cleanDate(d["Letter date"]), Timeline.cleanDate(d["Newsletter date"]))}</strong> days`
                // }
            })
    },
    cleanDate: (date) => {
        let clean = date.replace("-0?-", "Jan").replace("0?-", "01-")
        return new Date(clean);
    },
    distanceDate: (date1, date2) => {
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    },
    extent: () => {
        return d3.extent(filtered.map(d => Timeline.distanceDate(Timeline.cleanDate(d["Letter date"]), Timeline.cleanDate(d["Newsletter date"]))))
    },
    variables: () => {
        globalThis.margin = ({ top: 30, right: 40, bottom: 30, left: 70 })
        Timeline.draw(filtered)
    },
};

export default Timeline;